import { useEffect, useState } from "react"
import { DiaAtual } from "../../services/MesAtual"

const Header = ({ data, requisicao }) => {

    const { MesAtual, AnoAtual } = data.Footer
    const Senha = data.Senha

    const [ width, setWidth ] = useState("w-[1819px]")
    const [ display, setDisplay ] = useState("hidden")
    const [ form, setForm ] = useState({
        "id": "",
        "periodo": "",
        "senha": ""
    })

    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value,})
    }

    function handleSend (e) {
        e.preventDefault()

        if(Senha === form.senha){
            const parts = form.periodo.split('-')
            const invertData = `${parts[1]}-${parts[0]}`
            form.periodo = invertData
            requisicao(form)
            setDisplay("hidden")
        } else {
            window.alert("Senha incorreta")
        }
    }

    const frases = [
        "Foque em ser produtivo, e não em ser ocupado",
        "Obstáculos são degraus para o sucesso",
        "Ações de hoje moldam o amanhã"
    ]
    const dataAtual = DiaAtual()
    const [num, setNum] = useState(0)
    
    useEffect(() => {
        if ( window.innerWidth > 1847 ){
            setWidth("w-full")
        }

        if( dataAtual >= 10 ){
            setNum(1)
        } else if ( dataAtual >= 20 ) {
            setNum(2)
        }
    }, [])

    function handleClick(){
        if( display === "hidden" ){
            setDisplay("flex")
        } else {
            setDisplay("hidden")
        }
    }

    return(
        <header className={`flex justify-between py-4 px-5 bg-black text-zinc-100 ${width}`}>
            <h1 className="text-2xl bg-gradient-to-r from-emerald-700 to-emerald-400 p-2 rounded-lg">{frases[num]}</h1>
            <div className="flex gap-10">
                <button className="bg-gradient-to-r from-emerald-800 to-emerald-500 p-2 ml-1 rounded-lg uppercase" onClick={handleClick}>
                    Trocar parâmetros de busca
                </button>

                <form className={`absolute top-16 p-3 w-80 ${display} flex-col gap-4 bg-black rounded-b-lg z-50`} onSubmit={handleSend}>
                    <div className="flex flex-col gap-1">
                        <label>ID da loja:</label>
                        <input type="number" className="p-2 rounded-lg bg-gradient-to-r from-emerald-800 to-emerald-600 text-white" 
                            value={form.id} onChange={handleChange} placeholder="Codigo Empresa" name="id" required 
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label>Periodo de busca:</label>
                        <input type="month" className="p-2 rounded-lg bg-gradient-to-r from-emerald-800 to-emerald-600 text-white" 
                            value={form.month} onChange={handleChange} placeholder="Codigo Empresa" name="periodo" required  
                        />
                    </div>

                    <div className="flex flex-col gap-1">
                        <label>Senha:</label>
                        <input type="password" className="p-2 rounded-lg bg-gradient-to-r from-emerald-800 to-emerald-600 text-white" 
                            value={form.senha} onChange={handleChange} placeholder="Digite a Senha" name="senha" required 
                        />
                    </div>

                    <div className="flex justify-center">
                        <button className="bg-emerald-500 p-2 w-36 rounded-lg">Buscar</button>
                    </div>
                </form>

                <span className="text-xl">{MesAtual} {AnoAtual}</span>
            </div>
        </header>
    )
}

export default Header