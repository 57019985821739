import { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import Mes from './components/Mes';
import SideBar from './components/SideBar';
import Dia from './components/Dia';

import Semana from './components/Semana';
import Footer from './components/Footer';
import Loading from './components/Loading';
import Weather from './components/Weather';
import MesAtual from './services/MesAtual';

const padrao = {
  "id": "33",
  "periodo": MesAtual(),
  "senha": ""
}

function App() {

  const [data, setData] = useState([])
  const [dadosRequisicao, setDadosRequisicao] = useState(padrao)

  useEffect(() => {
    getData()

    if (dadosRequisicao.periodo === padrao.periodo) {
      const intervalId = setInterval(() => {
        getData(); // API sendo chamada a cada minuto
      }, 60000);

      // Função de limpeza para quando o componente é desmontado
      return () => clearInterval(intervalId);
    }
  }, [dadosRequisicao])

  async function getData() {
    try {
      const promisse = await fetch(`https://web.priorizatec.com.br/api/conexao-ativa/dash?empresa_id=${dadosRequisicao.id}&periodo=${dadosRequisicao.periodo}`)
      const response = await promisse.json()
      setData(response)
    } catch (error) {
      console.error('Error fetching data', error)
    }
  }

  if (data.length === 0) {
    return <Loading />
  }

  console.log(data)

  return (
    <>
      <Header data={data[0].SideBar} requisicao={setDadosRequisicao} />
      <div className='flex justify-between bg-gradient-to-b from-emerald-800 to-emerald-400 w-full'>
        <div className='bg-gradient-to-b from-emerald-800 to-emerald-400 pb-5'>
          <Mes data={data[0].Mes} />

          <div className='flex mt-5'>
            <Dia data={data[0].Dia} />
            <Semana data={data[0].Semana} />
          </div>
          {/* <Weather /> */}
        </div>

        <SideBar data={data[0].SideBar} />

      </div>

      <Footer data={data[0].SideBar} />

    </>
  );
}

export default App;
